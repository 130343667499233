/*
* This listener is for the left scrolling button being clicked
*/
$(document).on('click', 'button[data-paddle-tab-left]', (event) => {
  const tabGroup = $('.tab-group-scrollable')
  let currentPosition = tabGroup.scrollLeft()
  currentPosition = scrollTabGroup(tabGroup, 'left', currentPosition)
  updateButtonVisible(tabGroup, currentPosition)
})

/*
* This listener is for the right scroll button being clicked
* */
$(document).on('click', 'button[data-paddle-tab-right]', (event) => {
  const tabGroup = $('.tab-group-scrollable')
  let currentPosition = tabGroup.scrollLeft()
  currentPosition = scrollTabGroup(tabGroup, 'right', currentPosition)
  updateButtonVisible(tabGroup, currentPosition)
})

/*
* This block handles the main scrolling after the left or right paddle button has been clicked
*/
function scrollTabGroup (element, direction, currentPosition) {
  const maxScrollableDistance = calculateMaxScrollDistance(element)
  const scrollIncrementSize = element.innerWidth()

  if (direction === 'left') {
    currentPosition -= scrollIncrementSize
    currentPosition = currentPosition <= 0 ? 0 : currentPosition
  } else {
    currentPosition += scrollIncrementSize
    currentPosition = (currentPosition >= maxScrollableDistance) ? maxScrollableDistance : currentPosition
  }
  $(element).animate({
    scrollLeft: currentPosition
  }, 'slow')

  return currentPosition
}

/*
* This block takes in the element where the animation happened and returns the max scrolling distance of that element
*/
function calculateMaxScrollDistance (element) {
  const maxScrollableDistance = element[0].scrollWidth - element.innerWidth()
  return maxScrollableDistance
}

/*
* This block takes in the element where the animation happened and the current position of the scroll bar
*  to hide/show the button respectively
*/
function updateButtonVisible (element, currentScrollPosition) {
  const maxScrollableDistance = calculateMaxScrollDistance(element)

  if (currentScrollPosition > 0) {
    $(element).siblings('.left-paddle').removeClass('is-hidden')
  }
  if (currentScrollPosition <= 0) {
    $(element).siblings('.left-paddle').addClass('is-hidden')
  }
  if (currentScrollPosition >= maxScrollableDistance) {
    $(element).siblings('.right-paddle').addClass('is-hidden')
  }
  if (currentScrollPosition < maxScrollableDistance) {
    $(element).siblings('.right-paddle').removeClass('is-hidden')
  }
}

/*
* This block reacts to a data-tab being selected. The active tab is brought towards the center of the viewable
*   section of the client's device and then updates the button visibility, if the tab selected has the
*   (.tab-group-scrollable) CSS class.
*/
$(document).on('click', '[data-tab]', (event) => {
  const target = $(event.target)
  const tabGroupScrollable = target.parent('.tab-group-scrollable')

  /*
  * the length of the target parent element will be greater than 0 if the element has the (.tab-group-scrollable) CSS
  * class, meaning that we expect this div to scroll
  * */
  if (tabGroupScrollable.length > 0) {
    const startingPointOfTab = target.position().left
    const midPointOfViewableBar = (tabGroupScrollable[0].clientWidth / 2) - (target.innerWidth() / 2)

    /*
    *   This line will find and push the focused tab to the the middle point of the nav bar
    *   by comparing the viewable mid-point of the nav bar and the mid-point of the focused tab.
    *
    *   If the focused tab is less than (to the left) of the middle of the nav bar we find
    *   the difference and set the scrollDifference variable to that difference for the animation
    *   and the same process in the other direction if the focused tab is greater than (to the right) the middle
    *
    */
    const scrollDifferenceToMidPoint = (startingPointOfTab < midPointOfViewableBar)
      ? '-=' + (midPointOfViewableBar - startingPointOfTab)
      : '+=' + (startingPointOfTab - midPointOfViewableBar)

    $(tabGroupScrollable).animate({
      scrollLeft: scrollDifferenceToMidPoint
    }, 'slow', function () {
      const currentPosition = target.parent().scrollLeft()
      updateButtonVisible(tabGroupScrollable, currentPosition)
    })
  }
})

export default { scrollTabGroup }
