// TODO: This is an example controller for devs. We can remove it once we have other controllers.
// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <button id="button" data-action="hello#sayHello">Say Hello</button>
//   <h1 data-target="hello.output"></h1>
// </div>

import BaseController from './base_controller'

export default class extends BaseController {
  static targets = ['output']

  sayHello () {
    this.outputTarget.textContent = 'Hello, Stimulus!'
  }
}
