// when the form having "data-inline-editable-active" attribute gets focused, set the value of the attribute to true
$(document).on('focus', '[data-ujs-id=\'inline_editable_field\']', function () {
  const editForm = $(this).closest('[data-inline-editable-active=false]')
  editForm.attr('data-inline-editable-active', true)
  // allow text to be pre-select (pre-highlight) for target site editable scenario
  if ($(this).attr('data-focus-select') === 'true') $(this).select()
})

// Submit form having "data-inline-editable-active" attribute as true on blur action and also set the value of the attribute to false
$(document).on('blur', '[data-ujs-id=\'inline_editable_field\']', function () {
  const editForm = $(this).closest('[data-inline-editable-active=true]')
  if (editForm.data('inline-editable-active') === true) {
    editForm.submit()
  }
})

// Set the value of the "data-inline-editable-active" attribute to false on pressing "Enter"
// except for textarea, which should just have the normal enter interaction
$(document).on('keypress', '[data-ujs-id=\'inline_editable_field\']', function (e) {
  if (e.keyCode === 13 && this.tagName !== 'TEXTAREA') {
    const editForm = $(this).closest('[data-inline-editable-active=true]')
    editForm.attr('data-inline-editable-active', false)
  }
})
