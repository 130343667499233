import * as Sentry from '@sentry/browser'

Sentry.init({
  dsn: process.env.SENTRY_DSN_FRONT_END,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay()
  ],
  // Performance Monitoring
  // Capture 100% of the transactions, reduce in production! To set a uniform sample rate, this samples 20% of the requests.
  tracesSampleRate: 0.2,
  // Session Replay
  // This sets the sample rate at 20%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysSessionSampleRate: 0.2,
  // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  replaysOnErrorSampleRate: 1.0
})

Sentry.addTracingExtensions()
