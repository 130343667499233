// This code is adapted from Kathy's password validation implementation in
// Affinity and converted to take advantage of ES6 features.

// Methods relating to password requirements regex
// and if user update / sign up values are present

class PasswordFormValidator {
  constructor (passwordField, confirmField, requiredFields) {
    this.requiredFields = requiredFields.map((_index, field) => $(field))
    this.passwordField = $(passwordField)
    this.confirmField = $(confirmField)
  }

  formIsValid () {
    return this.validPasswordAndMatch() && this.requiredFields.toArray().every((field) => this.isPresent(field))
  }

  // ------- Helpers for checking if field values present -------

  // Check if form input with given value is empty
  // @param [String] selector - selector for form input
  isPresent (selector) {
    return $(selector).length > 0 && $(selector).val().trim() !== ''
  }

  // checks if password meets all the conditions
  // and has a matching password confirmation
  // @return [Boolean] - true if there's a valid password and match
  validPasswordAndMatch () {
    const input = this.passwordField.val()

    const numberCheck = this.hasNumber(input)
    const lowercaseCheck = this.hasLowercase(input)
    const uppercaseCheck = this.hasUppercase(input)
    const lengthCheck = this.atLeastEightCharacters(input)
    const symbolCheck = this.hasSpecialCharacter(input)
    const passwordMatch = this.passwordConfirmationMatch()

    return numberCheck && lowercaseCheck && uppercaseCheck &&
      lengthCheck && symbolCheck && passwordMatch
  }

  // Checks if password and password confirmation matches
  // @return [Boolean] password matches password conf
  passwordConfirmationMatch () {
    const password = this.passwordField.val()
    const conf = this.confirmField.val()

    return password === conf
  }

  // ---------- Regex Checks for password Requirements ----------

  // @param [String] input - text input
  // @return [Boolean] - whether input contains at least 1 digit
  hasNumber (input) {
    const regex = /(?=.*?[0-9])/
    return regex.test(input)
  }

  // @param [String] input - text input
  // @return [Boolean] - whether input contains a lowercase letter
  hasLowercase (input) {
    const regex = /(?=.*?[a-z])/
    return regex.test(input)
  }

  // @param [String] input - text input
  // @return [Boolean] - whether input contains an uppercase letter
  hasUppercase (input) {
    const regex = /(?=.*?[A-Z])/
    return regex.test(input)
  }

  // @param [String] input - text input
  // @return [Boolean] - whether input is at least 8 characters
  atLeastEightCharacters (input) {
    return input.length > 7
  }

  // @param [String] input - text input
  // @return [Boolean] - whether input has a special character (cannot detect slashes)
  hasSpecialCharacter (input) {
    const regex = /[!@#$%^&*(),.?":{}|<> _-]/
    return regex.test(input)
  }
}

export default PasswordFormValidator
