
function displayNotifly () {
  let delay = 0
  const notificationDuration = 5000 // Time before notificiation auto-closes

  $('#notifly .wrapper').each(function () {
    const self = this

    $(this).addClass('animate-in')

    setTimeout(function () {
      // Animate out after 5 seconds
      setTimeout(function () {
        $(self).addClass('animate-out').on('animationend', function () {
          $(self).remove()
        })
      }, notificationDuration)
    // Add an extra delay for each additional notification
    }, delay)

    delay += 250
  })
}

$(document).on('click', '#notifly .closer', function () {
  closeNotiflyMessage(this.parentNode)
})

function closeNotiflyMessage (msg) {
  $(msg).clearQueue().addClass('animate-out').on('animationend', function () {
    $(msg).remove()
  })
}

export default { displayNotifly, closeNotiflyMessage }
