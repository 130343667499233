// Hide modal
function closeModal () {
  $('.modal-mask').fadeOut(200)
  $('.modal').fadeOut(200)
  $('body').removeClass('modal-open')
  $('.modal').trigger('modal-close')
}

// Display modal
function displayModal () {
  $('.modal-mask').fadeIn(200)
  $('.modal').fadeIn(200)
  $('body').addClass('modal-open')
}

// Clicking modal_mask should be close the modal
$(document).on('click', '[data-btn=\'close_modal\'], [data-ujs-id=\'modal_mask\']', function () {
  closeModal()
})

$(document).keyup(function (e) {
  // escape key
  if (e.keyCode === 27) {
    closeModal()
  }
})

export default { displayModal, closeModal }
