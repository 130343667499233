// On click:
// - Close open overlays (except the overlay this click originated in, if any)
// - Open any overlays this element is linked to (if not already open)
$(document).on('click', function (e) {
  const closestOverlayKey = e.target.closest('[data-overlay-key]')
  const closestOverlayContent = e.target.closest('[data-overlay-content]')
  const overlayKeyStr = $(closestOverlayKey).data('overlay-key')
  const overlayContent = $('[data-overlay-content=\'' + overlayKeyStr + '\']')

  // Close open overlays (except the overlay this click originated in, if any)
  overlayFadeOut($('[data-overlay-content]:visible').not(closestOverlayContent))

  // Wait for show() to complete before adding .is-open (otherwise will not
  // show transition)
  overlayContent.not(':visible').show(0, function () {
    overlayContent.addClass('is-open')
  })
})

// Remove .is-open, allow overlay can transition out, then hide the element
function overlayFadeOut (overlayContent) {
  overlayContent.removeClass('is-open').one('transitionend', function () {
    overlayContent.hide()
  })
}

export default { overlayFadeOut }
