// This code is adapted from Kathy's password validation implementation in
// Affinity and converted to take advantage of ES6 features.

import PasswordFormValidator from './password_form_validator'

// listener for enabling the submit button on update user, update password, and sign up
$(document).on('keyup change focusout', '[data-password-validation] input', function (e) {
  const validationForm = $(e.target).closest('[data-password-validation]')
  const requiredFields = validationForm.find('[required]')
  const passwordField = validationForm.find('[data-password-field]')
  const confirmPasswordField = validationForm.find('[data-confirm-password-field]')

  const validator = new PasswordFormValidator(passwordField, confirmPasswordField, requiredFields)

  updateSubmitButton(validationForm, validator)

  requiredFields.each((_index, field) => updateFieldError(validationForm, $(field), validator))
  togglePasswordRequirementsList(validationForm, passwordField, validator)
})

$(document).on('focus', '[data-password-validation] input', (event) => {
  $(event.target).data('previously-focused', true)
})

function updateSubmitButton (form, validator) {
  const submitButton = form.find(':submit')
  const enableSubmit = validator.formIsValid()

  if (enableSubmit) {
    submitButton.removeClass('disabled')
    submitButton.removeAttr('disabled')
  } else {
    submitButton.addClass('disabled')
    submitButton.attr('disabled', 'disabled')
  }
}

function updateFieldError (validationForm, field, validator) {
  // Don't validate a field a user hasn't messed with yet.
  if (!field.data('previously-focused')) {
    return
  }

  let toggleCondition = false

  if (field.data('confirm-password-field')) {
    toggleCondition = !validator.passwordConfirmationMatch()
  } else {
    toggleCondition = !validator.isPresent(field)
  }

  toggleErrorMessage(field, toggleCondition)
}

// Checks if password input meets password requirement
// on modify requirements list accordingly
// @param [JQuery] input_field - field to check value for
function togglePasswordRequirementsList (validationForm, passwordField, validator) {
  const input = passwordField.val()

  const numberCheck = validator.hasNumber(input)
  const lowercaseCheck = validator.hasLowercase(input)
  const uppercaseCheck = validator.hasUppercase(input)
  const lengthCheck = validator.atLeastEightCharacters(input)
  const symbolCheck = validator.hasSpecialCharacter(input)

  updateRequirementStatus('.pw-character-count', lengthCheck)
  updateRequirementStatus('.pw-uppercase', uppercaseCheck)
  updateRequirementStatus('.pw-lowercase', lowercaseCheck)
  updateRequirementStatus('.pw-number', numberCheck)
  updateRequirementStatus('.pw-symbol', symbolCheck)
}

// ------ Helper methods for adding listeners or showing error messages

// Show error message for under input field
// @param [Jquery] form - input box
// @param [Boolean] condition - condition to show error
function toggleErrorMessage (form, condition) {
  let siblings = form.siblings()
  if (siblings.length === 0) {
    // forms submitted with error gets
    // put inside a div
    siblings = form.parent().siblings()
  }
  const label = $(siblings.filter($('label')))
  const errorMessage = $(siblings.filter($('.typing-error')))

  // update label, error message, and form color
  // based on if password matches
  if (condition) { // should show error message
    label.addClass('warning')
    errorMessage.removeClass('is-invisible')
    form.addClass('form-error')
  } else {
    label.removeClass('warning')
    errorMessage.addClass('is-invisible')
    form.removeClass('form-error')
  }
}

// @param [String] selector
// @param [Boolean] meetsRequirement - whether current input meets password requirement
function updateRequirementStatus (selector, meetsRequirement) {
  const passwordRequirement = $(selector)
  let icon = null

  if (meetsRequirement) {
    // remove red x and red text
    // add green check
    passwordRequirement.removeClass('warning')
    icon = $(selector + ' .fa-xmark')
    icon.removeClass('fa-xmark')
    icon.addClass('fa-check success')
  } else if ($(selector + ' .fa-check').length > 0) {
    // remove green check
    // add red x and text
    icon = $(selector + ' .fa-check')
    icon.removeClass('fa-check success')
    icon.addClass('fa-xmark')
    passwordRequirement.addClass('warning')
  }
}

export default {}
