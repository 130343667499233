import BaseController from './base_controller'

export default class extends BaseController {
  static targets = ['burger', 'dropdown']
  static classes = ['toggleBurger']

  toggleBurgerMenu () {
    this.burgerTarget.classList.toggle(this.toggleBurgerClass)
    this.dropdownTarget.classList.toggle(this.toggleBurgerClass)
  }
}
