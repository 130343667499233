/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/frontend and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

import { } from 'jquery-ujs'

import Turbolinks from 'turbolinks'

import ahoy from 'ahoy.js'

import Notifly from 'notifly'
import Overlay from 'overlay'
import { } from 'inline_edit'
import Modal from 'modal'
import ScrollableTabGroup from 'scroll_tab_group'
import { } from 'password_validation'
import { } from 'hubspot'

// Setup Sentry
import '../sentry/index'

const images = require.context('../images', true)
const imagePath = (name) => images(name, true) // eslint-disable-line no-unused-vars
Turbolinks.start()
require('@rails/activestorage').start()
require('channels')

require('@fortawesome/fontawesome-free/js/all.js')
require('@fortawesome/fontawesome-free/css/all.css')
require('stylesheets/application')

export { Notifly, Overlay, Modal, ScrollableTabGroup }

// Import our apps Stimulus controllers.
// controllers/index.js will import all controller files automatically.
// There is no need to add individual controllers here.
require('controllers')

// Configure Ahoy to track events client side.
ahoy.configure({ cookies: false })
ahoy.trackView()
ahoy.trackClicks('a, button, input[type=submit]')
ahoy.trackSubmits('form')
